import React, { useCallback } from "react";
import LiveCourseCard from "../Courses/LiveCourseCard";
import useEmblaCarousel from "embla-carousel-react";
import CardLoader from "@/components/Loaders/CardLoader";

const Bundles = ({ bundles }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true, slidesToScroll: 1 });
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="courses-area pb-70" style={styles.container}>
      <div className="container plr-0">
        <div className="carousel-section-container">
          <h2>Bundles</h2>
        </div>
        <div className="embla">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {bundles?.length > 0
                ? bundles.map((bundle) => (
                    <div key={bundle.bundleId} className="embla__slide">
                      <LiveCourseCard {...bundle} key={bundle.bundleId} />
                    </div>
                  ))
                : new Array(3).fill(0).map((val, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 embla__slide" key={val + i}>
                      <CardLoader uniqueKey="content-loader-key" />
                    </div>
                  ))}
            </div>
          </div>
          <button className="embla__prev" onClick={scrollPrev}>
            <i className="fi fi-sr-angle-small-left"></i>
          </button>
          <button className="embla__next" onClick={scrollNext}>
            <i className="fi fi-sr-angle-small-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bundles;

const styles = {
  container: { zIndex: "0" },
  pausedOverlay: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
};
