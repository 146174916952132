import React from "react";
import Link from "next/link";
import Image from "next/image";

const Features = () => {
  return (
    <div className="features-area ptb-70">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div style={{ marginTop: "20px" }} className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-features-box">
              <div className="icon">
                <Image width={112} height={112} src="/images/video-call.svg" alt="image" />
              </div>
              <h3>Interactive Learning</h3>
              <p>Engage with instructors in a live setting for an interactive learning experience.</p>

              <Link prefetch={false} legacyBehavior href="/courses/live-courses">
                <a style={styles.btnStyle} className="default-btn">
                  Upcoming Sessions <span></span>
                </a>
              </Link>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-features-box">
              <div className="icon">
                <Image width={112} height={112} src="/images/icons8-video-tutorials.svg" alt="image" />
              </div>
              <h3>On-Demand Learning</h3>
              <p>Watch videos or book a session for a course at a time that&apos;s convenient for you.</p>
              <Link prefetch={false} legacyBehavior href="/courses">
                <a style={styles.btnStyle} className="default-btn">
                  View Courses <span></span>
                </a>
              </Link>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-features-box">
              <div className="icon">
                <Image width={112} height={112} src="/images/discord-logo.svg" alt="image" />
              </div>
              <h3>Community</h3>
              <p>Join us on Discord to share your ideas, ask questions, and be part of our community!</p>
              <a
                target="_blank"
                style={styles.btnStyle}
                className="default-btn"
                rel="noopener noreferrer"
                href={`https://discord.gg/YQfkzxNe9x`}
              >
                Join Discord Chat<span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  btnStyle: { width: "fit-content", marginLeft: "auto", marginRight: "auto" },
};

export default Features;
