import React, { useState } from "react";
import Link from "next/link";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Image from "next/image";
import Messages from "../StreamChat/Messages";
import { useRouter } from "next/router";

const popover = () => (
  <Popover className="pop-over" id="popover-basic" translate="no">
    <Popover.Header className="pop-over-header" as="h2">
      Help & Resources
    </Popover.Header>
    <Popover.Body className="pop-over-body">
      <div className="row">
        <div className="col-lg-6 single-footer-widget pl5">
          <ul className="footer-links-list">
            <li>
              <Link legacyBehavior href="/">
                <a>Home</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/courses">
                <a>Courses</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/about">
                <a>About</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/contact">
                <a>Contact</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-6">
          <div className="single-footer-widget">
            <ul className="footer-links-list">
              <li>
                <Link legacyBehavior href="/faq">
                  <a>FAQs</a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/cookie-policy">
                  <a>Cookie Policy</a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/privacy-policy">
                  <a>Privacy Policy</a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/terms-of-service">
                  <a>Terms & Conditions</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="logo">
            <Image src={"/images/logo-png-small.png"} height={93} width={200} alt="logo"></Image>
          </div>
          <div className="footer-bottom-area">
            <p>
              <i className="bx bx-copyright"></i>
              {new Date().getFullYear()} Coursepage LLC
            </p>
            <ul className="social-links-list">
              <li>
                <a
                  href="https://www.facebook.com/people/Coursepage/61560865569177/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src="/icons/facebook-48.png" alt="Facebook" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://x.com/coursepage12" target="_blank" rel="noopener noreferrer">
                  <Image src="/icons/twitter-48.png" alt="Twitter" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/coursepage" target="_blank" rel="noopener noreferrer">
                  <Image src="/icons/linkedin-48.png" alt="LinkedIn" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@coursepage" target="_blank" rel="noopener noreferrer">
                  <Image src="/icons/youtube-48.png" alt="Instagram" width={24} height={24} />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/YQfkzxNe9x" target="_blank" rel="noopener noreferrer">
                  <Image src="/icons/discord.png" alt="Discord" width={24} height={24} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Popover.Body>
  </Popover>
);

const Help = () => {
  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => setShowPopover((prev) => !prev);

  return (
    <div className="go-top active">
      <OverlayTrigger
        trigger="click"
        placement="top"
        onToggle={togglePopover}
        overlay={showPopover ? popover() : <></>}
        rootClose
        flip={false}
      >
        <i className="bx bx-question-mark"></i>
      </OverlayTrigger>
    </div>
  );
};

const Footer = ({ user }) => {
  const router = useRouter();
  const [showHelp, setShowHelp] = useState(true);

  React.useEffect(() => {
    const handleRouteChange = () => {
      setShowHelp(false);
    };
    const handleRouteEnd = () => {
      setShowHelp(true);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteEnd);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      router.events.off("routeChangeComplete", handleRouteEnd);
    };
  }, [router.pathname]);

  return (
    <footer id="footer" className="footer-area">
      {showHelp && <Help />}
      <Messages user={user} />
    </footer>
  );
};

export default Footer;
